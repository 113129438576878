<template>
  <div>
    <div class="form-group row">
      <div class="col-lg-6 mb-5">
        <label>{{ $t('extra_discount.customer') }}<span class="text-danger">*</span></label>
        <multiselect
            id="customer_id"
            v-model="customer"
            :placeholder="$t('extra_discount.customer')"
            label="fullname"
            track-by="id"
            :options="[]"
            :multiple="false"
            :taggable="false"
            :show-labels="false"
            :show-no-options="false"
            :show-no-results="false">
        </multiselect>
        <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('extra_discount.customer') }}.</span>
        <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
              {{ validation.customer_id[0] }}
          </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('extra_discount.invoice') }}<span class="text-danger">*</span></label>
        <input type="text" v-model="data.invoice_id" class="form-control" :class="validation && validation.invoice_id ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.invoice_id" class="fv-plugins-message-container invalid-feedback">
          {{ validation.invoice_id[0] }}
      </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('extra_discount.discount_date') }}<span class="text-danger">*</span></label>
        <input type="date" v-model="data.discount_date" class="form-control" :class="validation && validation.discount_date ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.discount_date" class="fv-plugins-message-container invalid-feedback">
          {{ validation.discount_date[0] }}
      </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('extra_discount.invoice_total') }}<span class="text-danger">*</span></label>
        <input type="number" v-model="data.invoice_total" class="form-control" :class="validation && validation.invoice_total ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.invoice_total" class="fv-plugins-message-container invalid-feedback">
          {{ validation.invoice_total[0] }}
      </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('extra_discount.discount_val') }}<span class="text-danger">*</span></label>
        <input type="number" v-model="data.discount_val" class="form-control" :class="validation && validation.discount_val ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.discount_val" class="fv-plugins-message-container invalid-feedback">
          {{ validation.discount_val[0] }}
      </span>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('extra_discount.request_responsible_user') }}<span class="text-danger">*</span></label>
        <div class="input-group">
          <multiselect v-model="request_user"
                       :class="validation && validation.request_responsible_user ? 'is-invalid' : ''"
                       :placeholder="$t('extra_discount.request_responsible_user')"
                       label="name"
                       track-by="id"
                       :options="users"
                       :multiple="true"
                       :taggable="false"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false"
                       @search-change="getUsers($event)">
          </multiselect>
          <div class="input-group-prepend">
            <a class="btn btn-primary" href="/users/users" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
          </div>
        </div>
        <span v-if="validation && validation.request_responsible_user" class="fv-plugins-message-container invalid-feedback">
          {{ validation.request_responsible_user[0] }}
      </span>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('extra_discount.response_responsible_user') }}<span class="text-danger">*</span></label>
        <div class="input-group">
          <multiselect v-model="response_user"
                       :class="validation && validation.response_responsible_user ? 'is-invalid' : ''"
                       :placeholder="$t('extra_discount.response_responsible_user')"
                       label="name"
                       track-by="id"
                       :options="users"
                       :multiple="true"
                       :taggable="false"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false"
                       @search-change="getUsers($event)">
          </multiselect>
          <div class="input-group-prepend">
            <a class="btn btn-primary" href="/users/users" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
          </div>
        </div>
        <span v-if="validation && validation.response_responsible_user" class="fv-plugins-message-container invalid-feedback">
          {{ validation.response_responsible_user[0] }}
      </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('status') }}</label>
        <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch></b-form-checkbox>
      </div>
      <div class="col-lg-12 mb-5">
        <label>{{ $t('extra_discount.description') }}</label>
        <textarea name="" id="receipt_notes" v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
        <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
          {{ validation.description[0] }}
      </span>
      </div>

    </div>
    <div class="card-footer pl-0 pr-0 pb-0">
      <div class="row">
        <div class="col-lg-6">
          <button type="reset" class="btn btn-primary mr-2" @click="save" :disabled="old_status > 1 && data.status == 1">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "extraDiscount",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
  },
  data() {
    return {
      mainRoute: 'sales/payment_sales_invoices',
      mainRouteDependency: 'base/dependency',
      data: {
        customer_id: null,
        invoice_id: null,
        discount_date: null,
        invoice_total: null,
        discount_val: null,
        discount_setting_id: null, //discount_type
        request_responsible_user: [],
        response_responsible_user: null,
        status: null,
        description: null,
      },
      validation: null,
      customer: null,
      request_user: [],
      response_user: null,
      users: [],
    }
  },
  methods: {
    save() {
        this.create();
    },

    create() {

      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      }).then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      }
    },
  }
}
</script>

<style scoped>

</style>